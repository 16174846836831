export default {
  primary: {
    backgroundColor: 'transparent',
    color: 'black',
    border: 'none',
    borderBottom: 'solid 2px',
    borderTop: 'solid 2px',
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontSize: '0.9rem',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'grey'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}

export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.smallNavMenu, .hamburger': {
      display: 'none !important'
    },
    backgroundColor: 'white',
    '.container, .containerScrolled': {
      // backgroundColor: '#dcdac7',
      padding: '1rem',
      position: 'static'
    },

    '.logoLocationContainer': {
      display: 'flex',
      flexDirection: 'column',
      '::after': {
        content: "'COASTAL GOURMET GROUP'",
        color: 'black'
      }
    }
  },

  footer: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: '#dcdac7',
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.contactDetails-container, .multiButtonContainer': {
      display: 'none'
    },
    '.copyright': {
      padding: '0.25rem'
      // maxWidth: '60%',
    },
    '.image': {
      margin: '1rem'
      // '::after': {
      //   content: "'COASTAL GOURMET GROUP'",
      //   color: 'black',
      // },
    },
    '.socialIconsContainer': {
      margin: '1rem 0rem',
      svg: {
        width: '45px',
        height: '40px',
        margin: '0.5rem',
        color: 'primary',
        borderRadius: '500px',
        padding: '0.25rem',
        border: 'solid 1px',
        borderColor: 'primary',
        path: {
          fill: 'primary'
        }
      }
    }
    // '.gonationLogo': {
    //   float: 'right',
    //   alignSelf: 'flex-end',
    //   transform: 'translateY(-200%)',
    // },
  },

  ctaWidget: {
    display: 'none !important'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['auto', 'auto', 'auto'],
    marginBottom: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    '.hero_content_container': {
      // textAlign: 'left',
      padding: '3rem 1rem',
      order: '2',
      backgroundColor: 'rgb(255 255 255 / 40%)',
      textAlign: 'center',
      justifyContent: 'center',
      // alignItems: 'flex-start',
      position: ['static', 'static', 'absolute', ''],

      alignItems: 'center',
      margin: '0rem',
      maxWidth: '767px',
      width: '100%',
      transform: ['unset', 'unset', 'translate(-50%, -50%)'],

      margin: '0 auto',
      '.title': {
        order: '2',
        color: '#0c0c0c',
        fontSize: ['2rem', '', '2.5rem', ''],
        textTransform: 'initial',
        textTransform: 'unset'
      },
      '.subtitle': {
        order: '1',
        borderBottom: '1px solid black',
        paddingBottom: '0.5rem',
        color: 'black',
        fontFamily: 'body',
        fontSize: ['1.5rem', '1.75rem', '2rem'],
        textTransform: 'uppercase'
      },
      '.text': {}
    },
    img: {
      filter: 'brightness(0.9)'
    },

    '.slick-slider': {
      width: '100%',

      '.slick-slide': {
        '> div': {
          height: ['75vh', '', '50vh'],
          img: {
            height: ['75vh', '', '50vh']
          }
        }
      }
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  brandsTitle: {
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // textAlign: 'left',
    padding: ['3rem 1rem', '3.5rem 2rem'],
    // borderTop: '1px solid black',
    // borderBottom: '1px solid black',
    '.title': {
      paddingBottom: '1rem',
      fontWeight: 'bold',
      fontSize: '3rem',
      borderBottom: '3px solid',
      borderColor: 'primary',
      width: 'fit-content',
      margin: 'auto auto 2rem'
    },
    '.subtitle': {
      fontFamily: 'body',
      fontWeight: '100',
      opacity: '0.5',
      marginTop: '1rem'
    }
  },

  homepageShout: {},

  business1: {
    margin: '1rem',
    borderBottom: ['1px solid grey', '', 'unset'],
    paddingBottom: ['3rem', '', 'unset'],
    '.logo': {
      height: 'auto',
      width: 'fit-content',
      marginBottom: '2rem',
      maxWidth: '50%'
    },
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
      width: 'fit-content',
      paddingBottom: '1rem',
      fontWeight: 'bold',
      fontSize: '3rem',
      color: '#222120',
      fontFamily: 'display3'
    },
    '.subtitle': {
      fontFamily: 'body',
      fontWeight: '100',
      opacity: '0.5',
      marginBottom: '2rem',
      textTransform: 'uppercase'
    },
    '.text': {
      marginBottom: '2rem',
      fontWeight: '100'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageAbout: {
    position: 'relative',
    backgroundColor: 'rgb(102 68 56 / 74%)',
    color: 'white',
    '.title': {
      borderBottom: '3px solid white',
      paddingBottom: '2rem'
    },
    '.text': {
      color: 'white'
    },
    '::before': {
      content: "''",
      zIndex: '-1',
      background:
        "url('https://res.cloudinary.com/gonation/image/upload/v1616785084/sites/coastal-gourmet-group/gallery_28.jpg')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: ['', '', '', 'fixed'],
      position: 'absolute',
      top: '0rem',
      right: '0rem',
      left: '0rem',
      bottom: '0rem',
      width: '100%',
      height: '100%'
    }
  },

  homepageMenu: {},

  homepageCareers: {
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageGallery: {
    minHeight: '100px',
    '.slick-dots': {
      display: 'none!important'
    },
    '.slick-arrow': {
      color: 'secondary'
    },
    '.textContent': {
      padding: '0rem'
    }
  },

  homepageContactUs: {},

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    backgroundColor: 'white',
    // color: 'white',
    '.inputField, .textField ': {
      borderColor: 'primary',
      borderRadius: '0px',
      // color: 'white',
      '::placeholder': {
        // color: 'white',
      }
    },
    '.submitBtn': {
      marginTop: '2rem',
      color: 'white'
    }
  },

  locationMap: {}
}

export default {
  heading: 'Merriweather, serif',
  body: 'Merriweather Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Merriweather, serif',
  display2: 'Roboto, san-serif',
  display3: 'Open Sans, san-serif',
  display4: 'Playfair Display, san-serif',
  googleFonts: [
    'Merriweather:100,200,300,400,500,600,700,800,900',
    'Merriweather Sans:100,200,300,400,500,600,700,800,900',
    'Roboto:100,200,300,400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900'
  ]
  // customFamilies: [''],
  // customUrls: [''],
}
